import { FC, useEffect, useState } from 'react';
import { ClientForm } from '../../models/ClientForm';
import { DistributionMemberStatus } from '../../models/Distribution';
import { DocumentResponse } from '../../models/Document';
import { Placeholders } from '../../models/Placeholders';
import DateUtils from '../../utils/DateUtils';
import { useParams } from 'react-router';
import { PerformedAction } from '../../models/ClientFormVersionHistory';
import { useRecoilValue } from 'recoil';
import { currentFormUsersAtom } from '../../recoil/atoms/Forms';
import { useTranslation } from 'react-i18next';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import DotIcon from '../shared/icon/DotIcon';
import Accordion from '../shared/accordion/Accordion';
import { ClientFormUser } from '../../models/ClientFormUser';
import ProfileAvatar, { ImageSize } from '../shared/profile-image/ProfileAvatar';
import User from '../../models/User';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import { determineReviewApprovalStatus, UserActionStatus } from '../../utils/FormPermissionUtils';
import { ClientFormStatus } from '../../models/ClientFormStatus';
import useHistoriesForPerformingAction from '../../hooks/useHistoriesForPerformingAction';
import { currentClientAtom } from '../../recoil/atoms/Clients';

interface NoticeBarProps {
  clientForm: ClientForm;
}

enum NotificationStatus {
  Green,
  Red,
}

interface NotificationMessage {
  text: JSX.Element;
  status: NotificationStatus;
  formUsers?: ClientFormUser[];
}

const NoticeBar: FC<NoticeBarProps> = (props) => {
  const { clientForm } = props;
  const client = useRecoilValue(currentClientAtom);
  const [messages, setMessages] = useState<NotificationMessage[]>([]);
  const params = useParams<{ formId: string }>();
  const formUsers = useRecoilValue(currentFormUsersAtom);
  const { t } = useTranslation(['form', 'common', 'form-builder', 'risk', 'activity-type', 'distribution']);
  const currentUser = useRecoilValue(currentUserAtom);
  const userHistories = useHistoriesForPerformingAction(params.formId);

  useEffect(() => {
    if (!clientForm || !userHistories || !formUsers.length || !currentUser) return;

    const createActionMessage = (
      status: UserActionStatus | null,
      performAction: PerformedAction.ClientFormApprove | PerformedAction.ClientFormValidate,
    ): { selfMessage?: NotificationMessage; pendingMessage?: NotificationMessage } => {
      if (!status) return {};

      const selfMessage =
        status.canPerformAction && !status.hasPerformedAction && status.self?.requiresAction
          ? {
              text: (
                <div>
                  <span>{t(performAction === PerformedAction.ClientFormApprove ? 'approval-required-by-me' : 'review-required-by-me')}</span>
                </div>
              ),
              status: NotificationStatus.Red,
            }
          : undefined;

      const pendingMessage =
        status.pendingActionUsers?.length && !selfMessage
          ? {
              text: (
                <div>
                  <span>
                    {status.pendingActionUsers.length === 1
                      ? t(performAction === PerformedAction.ClientFormApprove ? 'approval-pending-by' : 'review-pending-by', {
                          name: status.pendingActionUsers[0].fullName,
                        })
                      : t(performAction === PerformedAction.ClientFormApprove ? 'approval-pending-by-more' : 'review-pending-by-more', {
                          count: status.pendingActionUsers.length,
                        })}
                  </span>
                </div>
              ),
              status: NotificationStatus.Red,
              formUsers: status.pendingActionUsers,
            }
          : undefined;

      return { selfMessage, pendingMessage };
    };

    const createCompletionMessage = (
      items: typeof userHistories,
      performAction: PerformedAction.ClientFormApprove | PerformedAction.ClientFormValidate,
      requiresSignature: boolean,
    ): NotificationMessage | undefined => {
      if (items.length === 0) return undefined;

      const isSingle = items.length === 1;
      const byMe = isSingle && items[0].activityPlaceholders[Placeholders.PRIMARY_USER]?.id === currentUser.id;
      const translationKey = isSingle
        ? requiresSignature
          ? performAction === PerformedAction.ClientFormApprove
            ? byMe
              ? 'approved-by-me-signed'
              : 'approved-by-signed'
            : byMe
              ? 'reviewed-by-me-signed'
              : 'reviewed-by-signed'
          : performAction === PerformedAction.ClientFormApprove
            ? byMe
              ? 'approved-by-me'
              : 'approved-by'
            : byMe
              ? 'reviewed-by-me'
              : 'reviewed-by'
        : requiresSignature
          ? performAction === PerformedAction.ClientFormApprove
            ? 'approved-by-signed-more'
            : 'reviewed-by-signed-more'
          : performAction === PerformedAction.ClientFormApprove
            ? 'approved-by-more'
            : 'reviewed-by-more';

      return {
        text: (
          <div>
            <span>
              {isSingle
                ? t(translationKey, { name: items[0].activityPlaceholders[Placeholders.PRIMARY_USER]?.userName })
                : t(translationKey, { count: items.length })}
            </span>{' '}
            {isSingle && <span className="text-gray-2">{DateUtils.formatDateTime(new Date(items[0].createdUtc || ''), false, true)}</span>}
          </div>
        ),
        status: NotificationStatus.Green,
        ...(isSingle
          ? {}
          : {
              formUsers: items.map(
                (item) =>
                  ({ ...formUsers.find((user) => user.id === item.userId && user.role === item.role), statusUtc: item.createdUtc }) as ClientFormUser,
              ),
            }),
      };
    };

    const isLastAction = Boolean(
      (clientForm.form.requiresValidation && !clientForm.form.requiresApproval && clientForm.status === ClientFormStatus.SubmittedForValidation) ||
        (clientForm.form.requiresApproval && clientForm.status === ClientFormStatus.SubmittedForApproval),
    );

    const reviewStatus =
      clientForm.status === ClientFormStatus.SubmittedForValidation
        ? determineReviewApprovalStatus(client?.id || '', currentUser, formUsers, userHistories, ClientFormUserRole.Validator, isLastAction)
        : null;

    const approvalStatus =
      clientForm.status === ClientFormStatus.SubmittedForApproval
        ? determineReviewApprovalStatus(client?.id || '', currentUser, formUsers, userHistories, ClientFormUserRole.Approver, isLastAction)
        : null;

    const { selfMessage: reviewRequiredSelfMessage, pendingMessage: pendingReviewsMessage } = createActionMessage(
      reviewStatus,
      PerformedAction.ClientFormValidate,
    );

    const { selfMessage: approvalRequiredSelfMessage, pendingMessage: pendingApprovalsMessage } = createActionMessage(
      approvalStatus,
      PerformedAction.ClientFormApprove,
    );

    const reviewedMessage = createCompletionMessage(
      userHistories.filter((user) => user.acceptedStatus && user.performedAction === PerformedAction.ClientFormValidate),
      PerformedAction.ClientFormValidate,
      clientForm?.form.requiresValidationSignature ?? false,
    );

    const approvedMessage = createCompletionMessage(
      userHistories.filter((user) => user.acceptedStatus && user.performedAction === PerformedAction.ClientFormApprove),
      PerformedAction.ClientFormApprove,
      clientForm?.form.requiresApprovalSignature ?? false,
    );

    const myDistributionMessages = ((clientForm as DocumentResponse)?.distribution?.members || [])
      .filter(
        (member) =>
          member.memberId === currentUser?.id &&
          (member.status === DistributionMemberStatus.Acknowledged || member.status === DistributionMemberStatus.Signed),
      )
      .map((member) => ({
        text: (
          <div>
            <span>
              {t(
                member.status === DistributionMemberStatus.Acknowledged
                  ? 'distribution:acknowledge.notice'
                  : 'distribution:acknowledge.notice-signed',
                {
                  date: DateUtils.formatDateTime(new Date(member.statusUtc), false, true),
                },
              )}
            </span>
          </div>
        ),
        status: NotificationStatus.Green,
      }));

    const requiredActionMessages = [reviewRequiredSelfMessage, approvalRequiredSelfMessage];

    const pendingMessages = [pendingReviewsMessage, pendingApprovalsMessage];

    const completionMessage = [reviewedMessage, approvedMessage];

    const newMessages: NotificationMessage[] = [
      ...requiredActionMessages,
      ...pendingMessages,
      ...completionMessage,
      ...myDistributionMessages,
    ].filter(Boolean);

    setMessages(newMessages);
  }, [userHistories, clientForm, formUsers, t, currentUser, client?.id]);

  const renderNoticeItem = (message: NotificationMessage, index: number) => {
    const colorClass = message.status === NotificationStatus.Green ? 'text-semantic-1' : 'text-semantic-2';

    if (message.formUsers && message.formUsers.length > 1) {
      return (
        <Accordion
          key={index}
          bodyClassName="bg-gray-5"
          titleClassName="bg-gray-5 rounded font-normal pl-0"
          wrapperClassName="mx-2 !mt-0 !mb-2"
          title={
            <div className="relative flex items-center gap-3 px-3 py-2">
              <DotIcon className={`${colorClass} h-[14px] w-[14px]`} pulse={message.status === NotificationStatus.Red} />
              <div>{message.text}</div>
            </div>
          }
        >
          {message.status === NotificationStatus.Green ? (
            <div className="flex flex-col gap-4 px-3 py-2">
              {message.formUsers.map((member) => (
                <div key={member.id} className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <ProfileAvatar user={member as User} size={ImageSize.XS} />
                    <span className="text-sm font-medium text-gray-700">{member.fullName}</span>
                  </div>
                  <span className="text-sm text-gray-500">{DateUtils.formatDateTime(new Date(member.statusUtc as string), false, true)}</span>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-wrap gap-4">
              {message.formUsers.map((member) => (
                <div key={member.id} className="flex items-center gap-2">
                  <ProfileAvatar user={member as User} size={ImageSize.XS} />
                  <span className="mt-2 text-sm font-medium text-gray-700">{member.fullName}</span>
                </div>
              ))}
            </div>
          )}
        </Accordion>
      );
    }
    return (
      <div key={index} className="bg-gray-5 relative mx-2 mb-2 flex items-center gap-3 rounded px-3 py-2">
        <DotIcon className={`${colorClass} h-[14px] w-[14px]`} pulse={message.status === NotificationStatus.Red} />
        <div>{message.text}</div>
      </div>
    );
  };

  return <div className="mt-2 flex flex-col">{messages.map(renderNoticeItem)}</div>;
};

export default NoticeBar;
